import LoadableComponent from '@components/Loadable';
import { PAGE_URL } from '@/config/AppConst';

// When user is logged, user go to page has restrict = true app auto redirect to Home Page
// Using restrict = false. Keep this page.
export const PublicRoutes = [
  {
    path: '/',
    title: 'CMS xinhxiu',
    component: LoadableComponent(() => import('@/screens/Dashboard')),
  },
  {
    path: PAGE_URL.LOGIN,
    title: 'CMS xinhxiu',
    component: LoadableComponent(() => import('@/screens/Login')),
  },
  {
    path: PAGE_URL.HOME,
    title: 'CMS xinhxiu',
    component: LoadableComponent(() => import('@/screens/Dashboard')),
  },
  {
    path: PAGE_URL.SHOP,
    title: 'CMS xinhxiu',
    component: LoadableComponent(() => import('@/screens/Shop')),
  },
  {
    path: PAGE_URL.SYNC_SHOPEE,
    title: 'CMS xinhxiu',
    component: LoadableComponent(() => import('@/screens/SyncDataShopee')),
  },
  {
    path: PAGE_URL.BLOG,
    title: 'CMS xinhxiu',
    component: LoadableComponent(() => import('@/screens/Blog')),
  },
  {
    path: PAGE_URL.BLOG_DETAIL,
    title: 'CMS xinhxiu',
    component: LoadableComponent(() => import('@/screens/Blog/DetailBlog')),
  },
  {
    path: PAGE_URL.CONFIG,
    title: 'CMS xinhxiu',
    component: LoadableComponent(() => import('@/screens/Config')),
  },
];

export const AuthRoutes = [
  {
    path: PAGE_URL.LOGIN,
    title: 'Login',
    component: LoadableComponent(() => import('@screens/Login')),
  },
];

export default [...PublicRoutes];
