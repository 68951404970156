export const TOKEN_KEY = 'access_token';
export const BALANCE_KEY = 'balance_user';
export const REFRESH_TOKEN_KEY = 'refresh_token';
export const ROLE_KEY = 'roles';
export const BROADCAST_CHANNEL_KEY = 'BROADCAST_CHANNEL_KEY';

export const PAGE_URL = {
  HOME: '/',
  LOGIN: '/login',
  SHOP: '/shop',
  BLOG: '/blog',
  BLOG_DETAIL: '/blog-detail',
  SYNC_SHOPEE: '/sync-shopee',
  CONFIG: '/config',
};

export const USER_PERMISSIONS = {
  ADMIN: 'admin',
  STANDER_USER: 'standUser',
};

export const VALIDATE_FORM = {
  MAX_LENGTH_INPUT: 256,
  MAX_LENGTH_TEXTAREA: 2048,
  MAX_LENGTH_PASSWORD: 20,
};

export const STATUS_USER = {
  ACTIVE: 'ACTIVE',
  NEW: 'NEW',
  INACTIVE: 'INACTIVE',
};

export const REGEX_APP = {
  REGEX_PHONE_NUMBER: /^0\d{9,14}$/,
  REGEX_EMAIL: /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/,
  REGEX_NOT_CONTAIN_ALL_SPACES: /^.*\S.*$/,
  REGEX_INTEGER: /^[1-9]\d*$/,
};

export const TYPE_DECOR = {
  ALL: '',
  BEDROOM: 'bedroom', // phòng ngủ
  INTERIOR: 'interior', // nội thất
  EXTERIOR: 'exterior', // ngoại thất
  EVENT: 'event', // phụ kiện
  TABLE: 'table', // góc làm việc
};

export const TYPE_SORT = {
  NEW: 'new',
  HIGH_LOW: 'high-low',
  LOW_HIGH: 'low-high',
  DISCOUNT: 'discount',
};

export const TYPE_PRICE = {
  LV1: 1, // 0-50.000
  LV2: 2, // 50.000-100.000
  LV3: 3, // 100.000-200.000
  LV4: 4, // > 200.000
};
