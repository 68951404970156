import { UserApi } from '@/config/ApiEndpoints';
import http from '../Http';

export const getListUser = (params) => {
  return http.post(UserApi.getListUser, params);
};

export const createUser = (params) => {
  return http.post(UserApi.create, params);
};

export const updateInfo = (params) => {
  return http.put(UserApi.update, params);
};

export const userChangePassword = (params) => {
  return http.post(UserApi.changePassword, params);
};

export const deleteUser = (id) => {
  return http.delete(UserApi.deleteUser(id));
};

export const deActiveUser = (id) => {
  return http.put(UserApi.deActiveUser(id));
};

export const activeUser = (id) => {
  return http.put(UserApi.activeUser(id));
};

export default {
  getListUser,
  createUser,
  updateInfo,
  userChangePassword,
  deleteUser,
  deActiveUser,
  activeUser,
};
