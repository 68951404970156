import { BlogApi } from '@/config/ApiEndpoints';
import http from '../Http';

export const getListBlog = (object) => {
  return http.post(BlogApi.getList, object);
};

export const createBlog = (object) => {
  return http.post(BlogApi.create, object);
};

export const updateBlog = (object) => {
  return http.put(BlogApi.update, object);
};

export default {
  getListBlog,
  createBlog,
  updateBlog,
};
