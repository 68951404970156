import { ProductApi } from '@/config/ApiEndpoints';
import http from '../Http';

export const getDetailProduct = (itemId) => {
  return http.get(ProductApi.getDetail(itemId));
};

export const getListProduct = (object) => {
  return http.post(ProductApi.getList, object);
};

export const create = (object) => {
  return http.post(ProductApi.create, object);
};

export const update = (object) => {
  return http.put(ProductApi.update, object);
};

export const activeProduct = (object) => {
  return http.post(ProductApi.active, object);
};

export default {
  getDetailProduct,
  getListProduct,
  create,
  update,
  activeProduct,
};
