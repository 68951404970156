import { PAGE_URL } from '@/config/AppConst';
// import { SocketContext } from '@/context/socket';
import { removeUser } from '@/store/slices';
import { AuthUtil } from '@/utils';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { socket } = useContext(SocketContext);

  // const handleLogOutSocket = () => {
  //   socket?.emit('logout', (err) => {
  //     if (err) {
  //       console.log('handleLogOutSocket error', err);
  //     }
  //   });
  // };

  const handleSignOut = async () => {
    setTimeout(() => {
      // handleLogOutSocket();
      dispatch(removeUser());
      AuthUtil.removeRefreshToken();
      AuthUtil.removeToken();
      return navigate(PAGE_URL.LOGIN);
    }, 100);
  };

  useEffect(() => {}, []);

  return (
    <header className="topbar">
      <div className="with-vertical">
        {/* ---------------------------------- */}
        {/* Start Vertical Layout Header */}
        {/* ---------------------------------- */}
        <nav className="navbar navbar-expand-lg p-0">
          <ul className="navbar-nav">
            <li className="nav-item d-flex d-xl-none">
              <a
                className="nav-link nav-icon-hover-bg rounded-circle  sidebartoggler "
                id="headerCollapse"
                href="javascript:void(0)">
                <iconify-icon
                  icon="solar:hamburger-menu-line-duotone"
                  className="fs-6"
                />
              </a>
            </li>
          </ul>
          <a
            className="navbar-toggler p-0 border-0 nav-icon-hover-bg rounded-circle"
            href="javascript:void(0)"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <iconify-icon
              icon="solar:menu-dots-bold-duotone"
              className="fs-6"
            />
          </a>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarNav">
            <div className="d-flex align-items-center justify-content-between">
              <ul className="navbar-nav flex-row mx-auto ms-lg-auto align-items-center justify-content-center">
                <li className="nav-item dropdown">
                  <a
                    href="javascript:void(0)"
                    className="nav-link nav-icon-hover-bg rounded-circle d-flex d-lg-none align-items-center justify-content-center"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#mobilenavbar"
                    aria-controls="offcanvasWithBothOptions">
                    <iconify-icon
                      icon="solar:sort-line-duotone"
                      className="fs-6"
                    />
                  </a>
                </li>
                {/* start profile Dropdown */}
                {/* ------------------------------- */}
                <li className="nav-item dropdown">
                  <a
                    className="nav-link"
                    href="javascript:void(0)"
                    id="drop1"
                    aria-expanded="false">
                    <div className="d-flex align-items-center gap-2 lh-base">
                      <img
                        src="images/default_avatar.jpg"
                        className="rounded-circle"
                        width={35}
                        height={35}
                        alt="matdash-img"
                      />
                      <iconify-icon
                        icon="solar:alt-arrow-down-bold"
                        className="fs-2"
                      />
                    </div>
                  </a>
                  <div
                    className="dropdown-menu profile-dropdown dropdown-menu-end dropdown-menu-animate-up"
                    aria-labelledby="drop1">
                    <div className="position-relative px-4 pt-3 pb-2">
                      <div className="d-flex align-items-center mb-3 pb-3 border-bottom gap-6">
                        <img
                          src="images/default_avatar.jpg"
                          className="rounded-circle"
                          width={56}
                          height={56}
                          alt="matdash-img"
                        />
                        <div>
                          <h5 className="mb-0 fs-12">
                            SangTX
                            <span className="text-success fs-11">Pro</span>
                          </h5>
                          <p className="mb-0 text-dark">
                            tranducsang.mta@gmail.com
                          </p>
                        </div>
                      </div>
                      <div className="message-body">
                        <div className="p-2 dropdown-item h6 rounded-1 pointer">
                          Tài khoản
                        </div>
                        <div
                          className="p-2 dropdown-item h6 rounded-1 pointer"
                          onClick={handleSignOut}>
                          Đăng xuất
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                {/* ------------------------------- */}
                {/* end profile Dropdown */}
                {/* ------------------------------- */}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default React.memo(Header);
