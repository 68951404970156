import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accessoryInfoInit: [],
  bomDiscountInit: [],
  listBomSellingInit: [],
};

export const listFilterQuotationSlice = createSlice({
  name: 'listFilterQuotation',
  initialState,
  reducers: {
    changeAccessoryInfor: (state, action) => {
      state.accessoryInfoInit = action.payload;
    },
    changeBomDiscount: (state, action) => {
      state.bomDiscountInit = action.payload;
    },
    changeListBomSelling: (state, action) => {
      state.listBomSellingInit = action.payload;
    },
  },
});

export const { changeAccessoryInfor, changeBomDiscount, changeListBomSelling } =
  listFilterQuotationSlice.actions;
// eslint-disable-next-line prettier/prettier, no-undef

export const selectListFilterQuotation = (state) => state.listFilterQuotation;

export default listFilterQuotationSlice.reducer;
