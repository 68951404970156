import { PAGE_URL } from '@/config/AppConst';
import {
  HomeOutlined,
  SettingOutlined,
  ShopOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {}, []);

  return (
    <aside className="side-mini-panel with-vertical">
      <div>
        <div className="iconbar">
          <div>
            <div className="mini-nav">
              <div className="brand-logo d-flex align-items-center justify-content-center">
                <a className="nav-link sidebartoggler" id="headerCollapse">
                  <iconify-icon
                    icon="solar:hamburger-menu-line-duotone"
                    className="fs-7"
                  />
                </a>
              </div>
              <ul className="mini-nav-ul" data-simplebar>
                <li
                  className={
                    location?.pathname === PAGE_URL.HOME
                      ? 'mini-nav-item pointer selected'
                      : 'mini-nav-item pointer'
                  }
                  id="mini-1">
                  <Tooltip title="Dashboard">
                    <a onClick={() => navigate(`${PAGE_URL.HOME}`, {})}>
                      <HomeOutlined />
                    </a>
                  </Tooltip>
                </li>
                <li
                  className={
                    location?.pathname === PAGE_URL.SYNC_SHOPEE
                      ? 'mini-nav-item pointer selected'
                      : 'mini-nav-item pointer'
                  }
                  id="mini-2">
                  <Tooltip title="Lấy dữ liệu từ Shopee">
                    <a onClick={() => navigate(`${PAGE_URL.SYNC_SHOPEE}`, {})}>
                      <SyncOutlined />
                    </a>
                  </Tooltip>
                </li>
                <li
                  className={
                    location?.pathname === PAGE_URL.SHOP
                      ? 'mini-nav-item pointer selected'
                      : 'mini-nav-item pointer'
                  }
                  id="mini-3">
                  <Tooltip title="Shop">
                    <a onClick={() => navigate(`${PAGE_URL.SHOP}`, {})}>
                      <ShopOutlined />
                    </a>
                  </Tooltip>
                </li>
                <li className="mini-nav-item pointer" id="mini-3">
                  <Tooltip title="Blog">
                    <a onClick={() => navigate(`${PAGE_URL.BLOG}`, {})}>
                      <iconify-icon
                        icon="solar:notes-line-duotone"
                        className="fs-7"
                      />
                    </a>
                  </Tooltip>
                </li>
                <li
                  className={
                    location?.pathname === PAGE_URL.CONFIG
                      ? 'mini-nav-item pointer selected'
                      : 'mini-nav-item pointer'
                  }
                  id="mini-4">
                  <Tooltip title="Config">
                    <a onClick={() => navigate(`${PAGE_URL.CONFIG}`, {})}>
                      <SettingOutlined />
                    </a>
                  </Tooltip>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default React.memo(Sidebar);
