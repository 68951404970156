import { AuthApi } from '@/config/ApiEndpoints';
import http from '../Http';

export const register = (params) => {
  return http.post(AuthApi.register, params);
};

export const login = (params) => {
  return http.post(AuthApi.login, params);
};

export const active = (params) => {
  return http.post(AuthApi.active, params);
};

export const forgotPassword = (params) => {
  return http.post(AuthApi.forgotPassword, params);
};

export const resetPassword = (params) => {
  return http.post(AuthApi.resetPassword, params);
};

export const authChangePassword = (params) => {
  return http.post(AuthApi.changePassword, params);
};

export const logout = () => {
  return http.post(AuthApi.logout);
};

export default {
  register,
  login,
  active,
  forgotPassword,
  resetPassword,
  logout,
  authChangePassword,
};
