/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import MainLayout from '@containers/Layout/MainLayout';
import Notfound from '@screens/NotFound';
import PublicRoute from './PublicRoute';
import { AuthRoutes, PublicRoutes } from './router.config';
import { AuthUtil, history } from '@/utils';
import { selectAuth } from '@/store/slices';
import { useSelector } from 'react-redux';
import { PAGE_URL } from '@/config/AppConst';

const Router = () => {
  const { currentUser } = useSelector(selectAuth);
  let navigate = useNavigate();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get('token');

  useEffect(() => {
    if (!AuthUtil.getToken() && !token) return navigate(PAGE_URL.LOGIN);
  }, [currentUser]);

  return (
    <Routes history={history}>
      {currentUser ? (
        <Route path="/" element={<MainLayout />}>
          {(PublicRoutes || []).map((route, index) => {
            return (
              <Route
                key={route.path}
                path={route.path || index}
                element={
                  <PublicRoute
                    roles={route.roles}
                    path={route.path}
                    component={route.component}
                    title={route.title}
                  />
                }
              />
            );
          })}
          <Route path="*" element={<Notfound />} />
        </Route>
      ) : (
        <Route path="/">
          {(AuthRoutes || []).map((route, index) => {
            return (
              <Route
                key={route.path}
                path={route.path || index}
                element={
                  <PublicRoute
                    roles={route.roles}
                    path={route.path}
                    component={route.component}
                    title={route.title}
                  />
                }
              />
            );
          })}
          <Route path="*" element={<Notfound />} />
        </Route>
      )}
    </Routes>
  );
};

export default Router;
