import { ConfigApi } from '@/config/ApiEndpoints';
import http from '../Http';

export const getInfoCompany = () => {
  return http.get(ConfigApi.getInfoCompany);
};

export const createInfoCompany = (object) => {
  return http.post(ConfigApi.createInfoCompany, object);
};

export const updateInfoCompany = (object) => {
  return http.put(ConfigApi.updateInfoCompany, object);
};

export default {
  getInfoCompany,
  createInfoCompany,
  updateInfoCompany,
};
