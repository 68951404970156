/* eslint-disable prettier/prettier */
import * as Yup from 'yup';
import i18n from '@/I18Next';

const testImage = (url, timeout) =>
  new Promise((res) => {
    timeout = timeout || 5000;
    let timedOut = false;
    let timer;
    const img = new Image();

    img.onerror = img.onabort = function () {
      if (!timedOut) {
        clearTimeout(timer);
        res('error');
      }
    };

    img.onload = function () {
      if (!timedOut) {
        clearTimeout(timer);
        res('success');
      }
    };

    img.src = url;

    timer = setTimeout(function () {
      timedOut = true;
      // reset .src to invalid URL so it stops previous
      // loading, but doesn't trigger new load
      img.src = '//!!!!/test.jpg';
      res('timeout');
    }, timeout);
  });

const testMinOrEqualString = (minString, minValue) => {
  let min = Number(`${minString || ''}`.replaceAll(',', ''));
  min = isNaN(min) ? 0 : min;

  return min >= minValue;
};

export const makeValidator = (validate) => {
  if (!validate) {
    throw new Error('Must provider validate rule');
  }

  const { t } = i18n;
  const { rules = [], type, label } = validate;
  let validator;

  switch (type) {
    case 'string':
      validator = Yup.string();
      break;
    case 'number':
      validator = Yup.number();
      break;
    case 'boolean':
      validator = Yup.bool();
      break;
    case 'date':
      validator = Yup.date();
      break;
    case 'object':
      validator = Yup.object();
      break;
    case 'array':
      validator = Yup.array();
      break;
    default:
      validator = Yup.string();
  }

  rules.forEach((rule) => {
    const { name, message } = rule;

    switch (name) {
      case 'required': {
        validator = validator.required(
          message || `${label} bắt buộc`
        );
        break;
      }
      case 'match': {
        const { fieldMatch, labelMatch } = rule;

        if (fieldMatch === undefined || labelMatch === undefined) {
          throw new Error('Must provider validate fieldMatch and labelMatch');
        }

        validator = validator.oneOf(
          [Yup.ref(fieldMatch)],
          message || `${label} không giống ${labelMatch}`
        );
        break;
      }
      case 'email': {
        validator = validator.email(message || t('Validate.email'));
        break;
      }
      case 'checkbox': {
        validator = validator.oneOf([true], message);
        break;
      }
      case 'min': {
        const { minValue, message } = rule;

        validator = validator.min(
          minValue,
          message ||
          t('Validate.min', {
            min: minValue,
            name: t(label),
          }),
        );
        break;
      }
      case 'max': {
        const { maxValue } = rule;

        validator = validator.max(
          maxValue,
          t('Validate.max', {
            max: maxValue,
            name: t(label),
          }),
        );
        break;
      }
      case 'minLength': {
        const { minLength } = rule;

        validator = validator.min(
          minLength,
          `${label} tối thiểu có ${minLength} ký tự`,
        );
        break;
      }
      case 'maxLength': {
        const { maxLength } = rule;

        validator = validator.max(
          maxLength,
          t('Validate.maxLength', {
            max: maxLength,
            name: t(label),
          }),
        );
        break;
      }
      case 'length': {
        const { length } = rule;

        validator = validator.length(
          length,
          t('Validate.length', {
            length,
            name: t(label),
          }),
        );
        break;
      }
      case 'image-url': {
        validator = validator.test(
          'valid-image-url',
          'Must use valid image URL',
          (value) =>
            testImage(value, 1000).then((status) => status === 'success'),
        );
        break;
      }
      case 'greater': {
        const { minValue } = rule;

        validator = validator.test(
          'greater',
          `${t(label)} must greater than ${minValue}`,
          (value) => {
            return Number(value) > Number(minValue);
          },
        );
        break;
      }
      case 'minOrEqual': {
        const { minValue, message } = rule;

        validator = validator.test('minOrEqual', message, (value) => {
          return testMinOrEqualString(value, minValue);
        });
        break;
      }
      // Todo: Add rule value
    }
  });

  return validator;
};

export default {
  makeValidator,
};
