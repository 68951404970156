export const AuthApi = {
  register: '',
  login: '/api/auth/login',
  active: '',
  forgotPassword: '',
  resetPassword: '',
  changePassword: '',
  logout: '',
};

export const UserApi = {
  getListUser: `/api/user/get`,
  update: '/api/user/update',
  create: '/api/user/create',
  changePassword: '/api/user/change-password',
  deleteUser: (id) => `/api/user/delete/${id}`,
  deActiveUser: (id) => `/api/user/de-active/${id}`,
  activeUser: (id) => `/api/user/active/${id}`,
};

export const ProductApi = {
  getDetail: (item_id) => `/api/product/detail/${item_id}`,
  getList: '/api/admin/product/getAll',
  create: '/api/admin/product/createProductFromShopee',
  update: '/api/admin/product/update',
  active: '/api/admin/product/active',
};

export const BlogApi = {
  getList: '/api/blog/getAll',
  update: '/api/blog/update',
  create: '/api/blog/create',
};

export const ConfigApi = {
  createInfoCompany: '/api/config/createInfoCompany',
  updateInfoCompany: '/api/config/updateInfoCompany',
  getInfoCompany: '/api/config/getInfoCompany',
};
