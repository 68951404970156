import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  listInforBomRedux: [],
};

export const listBOMSlice = createSlice({
  name: 'listBom',
  initialState,
  reducers: {
    removeListBom: (state) => {
      state.listInforBomRedux = [];
    },
    changeListBom: (state, action) => {
      state.listInforBomRedux = action.payload;
    },
  },
});

export const { removeListBom, changeListBom } = listBOMSlice.actions;
// eslint-disable-next-line prettier/prettier, no-undef

export const selectListBom = (state) => state.listBom;

export default listBOMSlice.reducer;
