import { createSlice } from '@reduxjs/toolkit';

const views = {
  structure: 'structure',
  table: 'table',
};

const initialState = {
  isShowStructure: false,
  viewResult: views.table,
};

export const systemSettingSlice = createSlice({
  name: 'systemSetting',
  initialState,
  reducers: {
    showViewStructure: (state) => {
      state.isShowStructure = true;
    },
    hideViewStructure: (state) => {
      state.isShowStructure = false;
    },
    changeViewUser: (state, action) => {
      state.viewResult = {
        ...state.viewResult,
        ...(action.payload || {}),
      };
    },
  },
  // extraReducers: () => { },
});

export const { showViewStructure, hideViewStructure, changeViewUser } =
  systemSettingSlice.actions;
export const selectSystemSetting = (state) => state.systemSetting;

// export const { actions } = authSlice;
export default systemSettingSlice.reducer;
