import { createSlice } from '@reduxjs/toolkit';
import { BROADCAST_CHANNEL_KEY } from '@/config/AppConst';

let broadcastChannel = {
  postMessage: () => {},
  addEventListener: () => {},
  removeEventListener: () => {},
};

// Safari 3.0+ "[object HTMLElementConstructor]"
const isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === '[object SafariRemoteNotification]';
  })(
    !window['safari'] ||
      (typeof safari !== 'undefined' && window['safari'].pushNotification),
  );

if (!isSafari) {
  broadcastChannel = new BroadcastChannel(BROADCAST_CHANNEL_KEY);
}

const initialState = {
  showMenu: false,
  broadcastChannel: broadcastChannel,
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    changeShowMenu: (state, action) => {
      state.showMenu = action.payload;
    },
  },
  // extraReducers: (builder) => {},
});

export const { changeShowMenu } = configSlice.actions;
export const selectConfig = (state) => state.config;

export default configSlice.reducer;
