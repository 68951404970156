/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import ReactDocumentTitle from 'react-document-title';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

// Hook
import {
  removeUser,
  selectAuth,
  selectConfig,
  storeUser,
} from '@/store/slices';
import { usePageTitle } from '@hooks';

// Component
import { Loading } from '@/components';
import Header from '@containers/Layout/Header';

// Context
import { LoadingContext } from '@/context/loading';
// import { SocketContext, getSocketDefault } from '@/context/socket';
import { AuthUtil } from '@/utils';
import { toast } from 'react-toastify';
import { PAGE_URL, TOKEN_KEY } from '@/config/AppConst';
import Sidebar from './Sidebar';

const MainLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser, isLoadingGetUserInfo, isLoadedUserInfo } =
    useSelector(selectAuth);
  const { broadcastChannel } = useSelector(selectConfig);

  const { pageTitle } = usePageTitle();

  const [isLoading, setIsLoading] = React.useState(false);
  const showLoading = React.useCallback(() => setIsLoading(true), []);
  const hideLoading = React.useCallback(() => setIsLoading(false), []);

  // const [socket, setSocket] = useState(getSocketDefault());
  // const updateSocket = () => {
  //   setSocket(getSocketDefault());
  // };

  useEffect(() => {
    if (isLoadedUserInfo || isLoadingGetUserInfo) return;

    if (currentUser && !AuthUtil.getToken()) {
      dispatch(removeUser());
      return;
    }
    // TODO
    // if (currentUser && !isLoadedUserInfo) {
    //   dispatch(getUserDetail());
    // }

    // updateSocket();
  }, [currentUser, dispatch, isLoadingGetUserInfo, isLoadedUserInfo]);

  const handleLoginNewDevice = useCallback(() => {
    toast.error('The account is logged in elsewhere');

    dispatch(removeUser());
    AuthUtil.removeRefreshToken();
    AuthUtil.removeToken();
    // updateSocket();
  }, [dispatch]);

  const handleLogoutOnMultiTab = useCallback(() => {
    dispatch(removeUser());
    AuthUtil.removeRefreshToken();
    AuthUtil.removeToken();
    // updateSocket();
  }, [dispatch]);

  useEffect(() => {
    // if (socket) {
    //   socket.on('new_session_login', handleLoginNewDevice);
    //   socket.on('logout-client', handleLogoutOnMultiTab);
    // }

    const handleLoginOtherTab = ({ data }) => {
      if (data.access_token) {
        AuthUtil.storeToken(data.access_token);
        dispatch(storeUser(data));
        location.reload();
      }
    };

    if (broadcastChannel) {
      broadcastChannel.addEventListener('message', handleLoginOtherTab);
    }

    return () => {
      // if (socket) {
      //   socket.off('new_session_login');
      //   socket.off('logout-client');
      // }

      if (broadcastChannel) {
        broadcastChannel.removeEventListener('message', handleLoginOtherTab);
      }
    };
  }, [
    broadcastChannel,
    dispatch,
    handleLoginNewDevice,
    handleLogoutOnMultiTab,
    // socket,
    // socket.connected,
  ]);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === TOKEN_KEY) {
        if (!event.newValue) {
          setTimeout(() => {
            dispatch(removeUser());
            AuthUtil.removeRefreshToken();
            AuthUtil.removeToken();
            return navigate(PAGE_URL.LOGIN);
          }, 100);
        }
      }
    };
    window.addEventListener('storage', function (event) {
      handleStorageChange(event);
    });
  }, [dispatch, navigate]);

  return (
    <LoadingContext.Provider value={{ showLoading, hideLoading }}>
      <ReactDocumentTitle title={pageTitle}>
        <div>
          <Loading show={isLoading} wrapperClassName="main-loading" />
          {/* <SocketContext.Provider value={{ socket, setSocket: updateSocket }}> */}
          <div id="main-wrapper" className="">
            <Sidebar />
            <div className="page-wrapper">
              <Header />
              <div className="body-wrapper">
                <div className="container-fluid">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
          <div className="dark-transparent sidebartoggler" />
          {/* </SocketContext.Provider> */}
        </div>
      </ReactDocumentTitle>
    </LoadingContext.Provider>
  );
};

export default MainLayout;
